import './App.css';
import { useEffect, useState } from 'react';
import { FaBars, } from 'react-icons/fa';
import { Mixpanel } from './Mixpanel';
import MainPage from './Components/MainPage';
import A from './Components/A';



function App() {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  const [theaterScreen, setTheaterScreen] = useState("home");

  const [sidebarVisible, setSidebarVisible] = useState(!isMobile);

  useEffect(() => {
    document.title = "Jeremy Lee";
    Mixpanel.track("User Logged In");
  })
  // check mobile
  const mapping = {
    1: 'white-key',
    2: 'black-key',
    3: 'white-key',
    4: 'black-key',
    5: 'white-key',
    6: 'white-key',
    7: 'black-key',
    8: 'white-key',
    9: 'black-key',
    10: 'white-key',
    11: 'black-key',
    12: 'white-key',
  };

  const getKeyboard = () => {
    var arr = [];
    var counter = 14;

    var list1 = [
      {
        name: "home"
      },
      {},{},{},{}, {
        name: "work"
      }, {}, {}, {}, {}, {}, {}
    ]
    var list2 = [
      {
        name: "music"
      },
      {},{},{},{}, {}, {}, {}, {}, {}, {}, {}
    ]

    const iterate = (list) => {
      list.forEach(({
        name
      }, i) => {
        arr.push(
          <div 
            className={mapping[i + 1]} 
            id={counter}
            style={{cursor: {name} && 'pointer'}}
            onClick={() => {
              if(name) {
                Mixpanel.track(name);
                setTheaterScreen(name);
              }

              if(isMobile){
                setSidebarVisible(false);
              }
            }}
          >
            {name}
        </div>);
        counter += 1;
      });
    }

    iterate(list1);
    iterate(list2);

    return arr;
  }


  const getOnTheater = () => {
    const Content = () => {
      if(theaterScreen == "home"){
        return <MainPage mixpanel={Mixpanel} isMobile={isMobile} />
      }else if(theaterScreen == "about"){
        return (
        <div style={{ textAlign: "left", padding: 10 }}>
          <div>I currently live in San Francisco, CA and work at <A href="https://rubbrband.com">Rubbrband</A>.</div>
          <br />
          <div>
            Things I'm interested in:
            <ul>
            <li>
              <b>Evaluation software.</b> Software that interprets photos, videos, and music and provides feedback. This is our primary focus at Rubbrband.
            </li>

            <li>
              <b>Audio Research.</b> What I mainly <A href="https://cnmat.berkeley.edu/people/jeremy-lee">worked on</A> prior to starting Rubbrband.
            </li>

            <li>
              <b>Classical Music.</b> Currently learning <A href="https://www.dailymotion.com/video/x7f06i2">sarcasm</A>.
            </li>
            </ul>
          </div>


        </div>)
      }else if(theaterScreen == "music"){
        return (
          <div style={{textAlign: "left", padding: 10}}>
          </div>
        )
      }else if(theaterScreen == "work"){
        return <div style={{ textAlign: "left", margin: 10 }}>
          <b>Research</b>

          <ul>
            <li>
              <A href="https://zenodo.org/record/7088385">
              Vivace: Web Application for Real-Time feedback on Piano Performance (2022)
              </A> 
              - By Jeremy Lee, Carmine Cella, Hélène-Camille Crayencour
            </li>

            <li>
              <A href="https://github.com/jrmylee/doppler">
              Doppler: Musical Recording Restoration using Variational-Auto-Encoders (2021)
              </A> 
              - By Jeremy Lee, Carmine Cella
            </li>
          </ul>

          <br />
          <br />

          <b>Startups</b>

          <ul>
            <li>
              <A href="https://rubbrband.com">
                CEO at Rubbrband
              </A>
              2022 - Present
            </li>

            <li>
              <A href="https://capeanalytics.com">
                Frontend Engineer at Cape Analytics
              </A>
              2019 - 2022
            </li>

            <li>
              <A href="https://altro.io/">
                Founding Engineer at Altro
              </A>
              2019 - 2020
            </li>
          </ul>

          <br />
          <br />
        </div>
      }
    }
    return (
      
        <Content />
      
    )
    
  }
  
  return (
    <div className="flex-column container">
      {
          isMobile && (
            <div style={{ }}>
              <FaBars onClick={() => setSidebarVisible(!sidebarVisible)} style={{ position: "absolute", top: 10, left: 10, fontSize: 30 }} />
            </div>
          )
      }
      {getOnTheater()}
      {
        sidebarVisible ?
         (
          <div className="piano">
            <div className="piano-top">
              <div className="steinway">
                
              </div>
            </div>
            <div className="keyboard">
              {getKeyboard()}
            </div>
          </div>
         ) : (
          <></>
         )
      }
  </div>
  );
}

export default App;
