import { useState } from 'react';
import { FaPlay, FaEnvelope, FaTwitter, FaLinkedin, FaSpotify, FaEllipsisH, } from 'react-icons/fa';
import A from './A';
import ReactPlayer from 'react-player'
import widmung from '../assets/widmung.mp3';
import sarcasm2 from '../assets/sarcasm2.m4a';
import sarcasm3 from '../assets/sarcasm3.m4a';
import love from '../assets/whenifallinlove.m4a';
import rameau from '../assets/Rameau.m4a';

const MainPage = ({ isMobile, mixpanel }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [currentTrack, setCurrentTrack] = useState(widmung);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isSpinning, setIsSpinning] = useState(false);

    const recordings = [
      { name: "Gavotte in Am", composer: "Rameau", onPress: () => {
        setCurrentTrack(rameau);
        setIsPlaying(!isPlaying);
        setIsSpinning(!isSpinning);
      }},
      { name: "Sarcasms No. 2", composer: "Prokofiev", onPress: () => {
        setCurrentTrack(sarcasm2);
        setIsPlaying(!isPlaying);
        setIsSpinning(!isSpinning);
      }},
      { name: "Sarcasms No. 3", composer: "Prokofiev", onPress: () => {
        setCurrentTrack(sarcasm3);
        setIsPlaying(!isPlaying);
        setIsSpinning(!isSpinning);
      }},
        { name: "Widmung", composer: "Schumann-Liszt", onPress: () => {
          setCurrentTrack(widmung);
          setIsPlaying(!isPlaying);
          setIsSpinning(!isSpinning);
        }  },
        { name: "When I Fall in Love", composer: "Victor Young", onPress: () => {
          setCurrentTrack(love);
          setIsPlaying(!isPlaying);
          setIsSpinning(!isSpinning);
        }}
    ]
    return (
      <div style={{
        display: "flex",
        width: isMobile ? "100vw" : 'calc(100vw - 400px)',
        justifyContent: isMobile ? "center" : "space-between",
        flexWrap: "wrap",
        marginLeft: isMobile ? 0 : 0,
      }}>
        <div style={{ 
          display: "flex", 
          flexDirection: "column", 
          // alignItems: 'center', 
          width: isMobile ? "100vw" : 500, 
          backgroundColor: "#d5e4f4" ,
          padding: 30,
          marginBottom: 20,
          }}>
          <img className="image" src={require("../assets/head.png")} width={200} />
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
            <b style={{ marginRight: 10 }}>Jeremy Lee</b>

            <A href='mailto:jeremy@rubbrband.com'>
              <FaEnvelope style={{marginRight: 10}}/>
            </A>
              
            <A href='https://www.linkedin.com/in/jeremy-l-a90742b8/'>
              <FaLinkedin style={{marginRight: 10}}/>
            </A>
            <A href='https://twitter.com/jrmyjlee'> 
              <FaTwitter style={{marginRight: 10}}/>
            </A>
          </div>
    
          <div style={{display: "none" }}><ReactPlayer
          url={currentTrack}
          playing={isPlaying}
          controls={false}
        /></div>

          <div style={{ fontSize: 14, marginTop: 20 }}>
            I live in San Francisco and am a co-founder of <a href="https://rubbrband.com">Rubbrband</a>. <br /> <br />

            Previously, I was a undergrad researcher at Berkeley, building software that can listen to and understand music.  <br /> <br />

            My main interests are: <br />
            <ul>
              <li><b>Software for creativity</b> <br /> At <a href="https://rubbrband.com">Rubbrband</a>, we're building a new way to make stuff.
              </li>
              <li><b>Solo piano</b> <br />
              I compose and perform solo piano music. <br /> <br />
              </li>
            </ul>
          </div>
        </div>
  
        <div style={{
            width: isMobile ? "100vw" : 300,
            height: 500,
            padding: 10,
            backgroundColor: "#d5e4f4",
        }}>
            <div style={{ textDecoration: "underline" }}>Recordings</div>

            <br />

            <div style={{ display: "flex", flexDirection: "column" }}>
                {
                    recordings.map((recording) => {
                        return (
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div onClick={() => {
                                  mixpanel.track(recording.name);
                                  recording.onPress();
                                }} style={{
                                    cursor: "pointer",
                                }}>
                                  <FaPlay style={{ marginRight: 3 }} size={12} />{recording.name}</div>
                                <div style={{ color: "grey" }}>{recording.composer}</div>
                            </div>
                        )
                    })
                }
            </div>

            <div class="record-player">
                <div class={`record`}>
                    <div class="record-center"></div>
                    <div class={`record-sheen`} style={{
                      'animation-play-state': isSpinning ? "running" : "paused",
                    }}></div>
                </div>
            </div>

            
        </div>
  
      </div>
    )
  }
  
export default MainPage;